import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Template from './components/partial/template';

// import our react pages
import Demo from './components/pages/demo';
import Home from './components/pages/home';
import HCPForm from './form/HCP/pages/main';
import sampleOrderOnline from './components/pages/SampleOrderOnline';
import SampleOrderHCPInfo from './form/components/SampleOrderHCPInfo';
import main from './form/OnlineFormSalesRep/index';
// import './css/style.css';
// import './scss/style.scss';
// import './worker';

const onUpdate = () => {
  return window.scrollTo(0, 0);
};

/*
    Usage: Nest your react routes under the switch route

    <Switch>
      <Route path="/cool-route" component={CoolRoute} />
    </Switch>

    In this example, this would render the Home page inside the Template accessible by visiting
    the root route.
*/

ReactDOM.render(
  <Router onUpdate={onUpdate}>

    <Template>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/demo" component={Demo} />
        <Route exact path="/BaseForm" component={HCPForm} />
        <Route exact path="/sampleOrderOnline" component={sampleOrderOnline} />
        <Route exact path="/SampleOrderHCPInfo" component={SampleOrderHCPInfo} />
        <Route exact path="/main" component={main} />
        <Route exact path="/HCPForm" component={HCPForm} />
      </Switch>
    </Template>

  </Router>,
  document.getElementById('app')
);
