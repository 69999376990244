import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, Popover, Overlay,Form, Row, Col,Card,InputGroup, ListGroup, OverlayTrigger,FormControl, Tooltip } from 'react-bootstrap';
//import Card from 'react-bootstrap/Card'
//import {Popover} from 'react-bootstrap/Popover';
import {connect} from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
//import {Request, defaults} from 'request';
import axios from 'axios';

export default class DeliveryInstructions extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state={
            deliveryInstructions : ''
        };
    }
    EnterDeliveryInstruction(e)
    {
        this.setState({
            deliveryInstructions: e.target.value
        });
        this.props.masterFunction._SetDeliveryInstructions(e.target.value);
    }

    render()
    {
        return(
            
                 <Form.Group as={Row} controlId = "deliveryInstruction">
                    <Form.Label column xs="auto"> Delivery Instruction </Form.Label>
                      <Col xs="auto">
                      <Form.Control  value= {this.state.deliveryInstruction} type="text" onChange={
                          this.EnterDeliveryInstruction
                      } />{"  "}
                      </Col>
                    
                    </Form.Group>
           
        );
    }
}





