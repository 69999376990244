import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { AddCircleOutline } from '@material-ui/icons';

import HCPAddAddress from './HCPAddAddress';

import {
  TextField,
  Button,
  Modal,
  MenuItem,
  Select,
  Paper,
  Grid,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    padding: '1% 8% 4% 8%',

    '& > *': {
      //   margin: theme.spacing(1),
      //   width: theme.spacing(16),
      //   height: theme.spacing(16),

      width: '100%',
      height: 'auto',
    },
  },
  labelTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelText: {
    textAlign: 'left',
    font: 'normal normal normal 16px/21px Roboto',
    color: '#333232',
    opacity: '1',
  },
  containerPaper: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    padding: '3%',
    marginBottom: '4%',
  },
  depotSubTitle: {
    margin: '0% 0% 2% 0%',
    color: '#333232',
    float: 'left',
  },
  table: {
    minWidth: 700,
  },
  depotButtonTypo: {
    float: 'right',
  },
  textFieldMaxWidth: {
    width: '100% !important',
  },
  containerMargin: {
    marginBottom: '5px',
  },
  depotButton: {
    color: '#fff',
    background: '#D52B1E',
    textAlign: 'center',
    '&:hover': {
      background: '#D52B1E',
    },
  },
  addressButtonText: {
    fontSize: '0.6375rem',
  },
}));

const HCPDetails = (props) => {
  const classes = useStyles();
  const fetchFormData = (formType) => {
    return;
  };

  //States
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <h4>HCP Details </h4>
      <Paper elevation={4} className={classes.containerPaper}>
        <Grid container className={classes.containerMargin}>
          <Grid container item spacing={1} xs={6}>
            <Grid item xs={6} className={classes.labelTextContainer}>
              <label className={classes.labelText} htmlFor="HCPName">
                HCP Name
              </label>
            </Grid>
            <Grid item xs={6}>
              <TextField
                className={classes.textFieldMaxWidth}
                disabled
                id="HCPName"
                variant="outlined"
                value={props.hcpDetails.Name}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.containerMargin}>
          <Grid container item spacing={1} xs={12}>
            <Grid item xs={3} className={classes.labelTextContainer}>
              <label className={classes.labelText} htmlFor="HCPAddress">
                HCP Address
              </label>
            </Grid>
            <Grid item xs={7}>
              <Select
                id="HCPId"
                variant="outlined"
                className={classes.textFieldMaxWidth}
                defaultValue={0}
              >
                {props.hcpDetails.Address.map((address, i) => {
                  return (
                    <MenuItem key={i} value={i}>
                      {address.Line1 +
                        ', ' +
                        address.Line2 +
                        ', ' +
                        address.Line3 +
                        '(' +
                        address.AreaCode +
                        ')'}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Button size="large" variant="contained" onClick={handleOpen}>
                <AddCircleOutline />
                <div className={classes.addressButtonText}>Add Address</div>
              </Button>
              <Modal open={open} onClose={handleClose}>
                <HCPAddAddress />
              </Modal>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default HCPDetails;
