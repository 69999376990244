/* eslint-disable no-multi-spaces */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-return */
import React from 'react';
/// //////////React Boostrap/////////
// import { Button, Form } from 'react-bootstrap';

// Material UI
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { AddCircleOutlineIcon } from '@material-ui/icons';
import {
  Button,
  Typography,
  Paper,
  FormHelperText,
  Grid,
  Checkbox,
} from '@material-ui/core';

// Customs
import HCPDetails from '../../components/HCPDetails';
import ProductDetails from '../../components/ProductDetails';

/// /States////

/// //Main////

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '1% 8% 4% 8%',

    '& > *': {
      //   margin: theme.spacing(1),
      //   width: theme.spacing(16),
      //   height: theme.spacing(16),

      width: '100%',
      height: 'auto',
    },
  },
  depotTitle: {
    margin: '0% 0% 2% 2%',
    color: '#333232',
  },
  depotPaper: {
    // width: '100%',
    // height: 'auto',
    //margin: '4% 8% 4% 8%',

    padding: '3%',
    marginBottom: '4%',
  },
  depotSubTitle: {
    margin: '0% 0% 2% 0%',
    color: '#333232',
    float: 'left',
  },
  table: {
    minWidth: 700,
  },
  depotButtonTypo: {
    float: 'right',
  },
  depotButton: {
    color: '#fff',
    background: '#D52B1E',
    textAlign: 'center',
    '&:hover': {
      background: '#D52B1E',
    },
  },
  headerBottomPadding: {
    marginBottom: '20px',
  },
  labelCheckBoxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  labelText: {
    textAlign: 'left !important',
    font: 'normal normal normal 16px/21px Roboto',
    color: '#333232',
    opacity: '1',
  },
  textFieldMaxWidth: {
    width: '100% !important',
  },
  paperMaxWidth: {
    width: '100% !important',
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
  },
}));

const mainForm = (props) => {
  const classes = useStyles();
  const fetchFormData = (formType) => {
    return;
  };

  const from_server = {
    HCP: {
      Name: 'John',
      ID: 'V33v@iD',
      Speciality: 'Diabeties',
      Address: [
        {
          Line1: '123/4',
          Line2: 'Some1 Suburb',
          Line3: 'Some 5City',
          AreaCode: 'Some5 Code',
        },
        {
          Line1: '15423/4',
          Line2: 'Some2 Suburb',
          Line3: 'Some 6City',
          AreaCode: 'Some2 Code',
        },
        {
          Line1: '32523/4',
          Line2: 'Some3 Suburb',
          Line3: 'Some 4City',
          AreaCode: 'Some6 Code',
        },
      ],
    },
    Products: {
      TotalCount: 7,
      Brands: [
        {
          Name: 'Humalog',
          ID: '112324124',
          Products: [
            { ID: '103124', Name: '5mg', Quantities: [1, 2, 3, 5, 7] },
            { ID: '201312', Name: '10mg', Quantities: [1, 2, 5, 7] },
            { ID: '403242', Name: '15mg', Quantities: [4, 6, 8] },
            { ID: '504365', Name: '25mg', Quantities: [1, 5, 7] },
          ],
        },
        {
          Name: 'Humaline',
          ID: '122324124',
          Products: [
            { ID: '113124', Name: '2mg', Quantities: [1, 2, 3, 5, 7] },
            { ID: '211312', Name: '20mg', Quantities: [1, 2, 5, 7] },
            { ID: '432142', Name: '35mg', Quantities: [4, 6, 8] },
            { ID: '543615', Name: '125mg', Quantities: [1, 5, 7] },
          ],
        },
        {
          Name: 'Almita',
          ID: '123324124',
          Products: [
            { ID: '123124', Name: '1mg', Quantities: [1, 2, 3, 5, 7] },
            { ID: '212312', Name: '13mg', Quantities: [1, 2, 5, 7] },
            { ID: '432242', Name: '14mg', Quantities: [4, 6, 8] },
            { ID: '543265', Name: '15mg', Quantities: [1, 5, 7] },
          ],
        },
        {
          Name: 'Forteo',
          ID: '142324124',
          Products: [
            { ID: '133124', Name: '15mg', Quantities: [1, 2, 3, 5, 7] },
            { ID: '213312', Name: '110mg', Quantities: [1, 2, 5, 7] },
            { ID: '432342', Name: '115mg', Quantities: [4, 6, 8] },
            { ID: '543635', Name: '215mg', Quantities: [1, 5, 7] },
          ],
        },
        {
          Name: 'Taltz',
          ID: '125324124',
          Products: [
            { ID: '143124', Name: '25mg', Quantities: [1, 2, 3, 5, 7] },
            { ID: '214312', Name: '210mg', Quantities: [1, 2, 5, 7] },
            { ID: '432442', Name: '125mg', Quantities: [4, 6, 8] },
            { ID: '543645', Name: '225mg', Quantities: [1, 5, 7] },
          ],
        },
        {
          Name: 'Cyramza',
          ID: '123624124',
          Products: [
            { ID: '153124', Name: '35mg', Quantities: [1, 2, 3, 5, 7] },
            { ID: '215312', Name: '130mg', Quantities: [1, 2, 5, 7] },
            { ID: '432542', Name: '135mg', Quantities: [4, 6, 8] },
            { ID: '543565', Name: '235mg', Quantities: [1, 5, 7] },
          ],
        },
        {
          Name: 'Trulicity',
          ID: '123241724',
          Products: [
            { ID: '163124', Name: '54mg', Quantities: [1, 2, 3, 5, 7] },
            { ID: '216312', Name: '140mg', Quantities: [1, 2, 5, 7] },
            { ID: '432642', Name: '145mg', Quantities: [4, 6, 8] },
            { ID: '543665', Name: '254mg', Quantities: [1, 5, 7] },
          ],
        },
      ],
    },
  };
  return (
    <div className={classes.root}>
      <h3 className={classes.headerBottomPadding}>Initiate Sample Request</h3>

      <HCPDetails hcpDetails={from_server.HCP} />
      <ProductDetails Products={from_server.Products} />

      <Paper className={classes.paperMaxWidth}>
        <Grid container>
          <Grid item xs={1} className={classes.labelTextContainer}>
            <Checkbox
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
          <Grid
            container
            item
            xs={11}
            className={classes.labelCheckBoxContainer}
          >
            <Grid item xs={12} className={classes.labelCheckBoxContainer}>
              <h5>Header</h5>
            </Grid>
            <Grid item xs={12} className={classes.labelCheckBoxContainer}>
              <label className={classes.labelText} htmlFor="HCPName">
                I confirm that I have been given the opportunity to read the
                Terms of Use and Privacy Policy that describes how my personal
                information will be used by Lilly, the terms of how Lilly may
                contact me, and information about my rights (including the right
                to revoke consent, right of correction and deletion, right for
                information about which of my personal data are processed, and
                the right to access these personal data). By agreeing (signing
                or checking this box), I confirm that I have read, understood,
                and approved that my personal data is treated as described in
                the Privacy Policy.
              </label>
            </Grid>
          </Grid>
          <Grid item xs={1} className={classes.labelTextContainer}>
            <Checkbox
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
          <Grid
            container
            item
            xs={11}
            className={classes.labelCheckBoxContainer}
          >
            <Grid item xs={12} className={classes.labelCheckBoxContainer}>
              <h5>Header</h5>
            </Grid>
            <Grid item xs={12} className={classes.labelCheckBoxContainer}>
              <label className={classes.labelText} htmlFor="HCPName">
                I give my consent for the use of my personal information,
                including the above email address, to provide me with
                information about products (including promotional materials)
                and/or services and access to new resources, in accordance with
                the conditions provided in the Privacy Policy. I may stop
                participating in this program at any time by using the
                unsubscribe link provided in the communication or as outlined in
                the Privacy Policy.
              </label>
            </Grid>
          </Grid>
          <Grid container justify="center">
            {' '}
            <Grid item xs={2} className={classes.labelTextContainer}></Grid>
            <Grid item xs={2} className={classes.labelTextContainer}>
              <Button
                size="large"
                variant="contained"
                // onClick={() => alert('Discard Not Implemented yet')}
              >
                Discard
              </Button>
            </Grid>
            <Grid item xs={1} className={classes.labelTextContainer}></Grid>
            <Grid item xs={2} className={classes.labelTextContainer}>
              <Button
                size="large"
                variant="contained"
                // onClick={() => alert('Submit not implemented yet!!')}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default mainForm;
