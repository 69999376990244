import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, Popover, Overlay,Form, Row, Col,Card } from 'react-bootstrap';
//import Card from 'react-bootstrap/Card'
import {connect} from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import SampleOrderHCPSearch from './SampleOrderHCPSearch';

export default class SampleOrderHCPInfo extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state=
        {
            hcpId :'',
            hcpName :'',
            hcpProfession : '',
            hcpSpecialty: '',
            hco_data:[]
        }
        this.setMasterState=this.setMasterState.bind(this);

    }
    componentDidMount(){
      console.log('------------------------regenerating componenet did mount state');
      if(this.props!=null && this.props!=undefined){
        if(this.props._SetStateForMainForm!=null && this.props._SetStateForMainForm!=undefined){
          if(this.props._SetStateForMainForm.hcp!=null && this.props._SetStateForMainForm.hcp!=undefined){
            let data= this.props._SetStateForMainForm.hcp;
            this.setState({
              hcpId:data.customer_id,
              hcpName :data.full_name,
              hcpProfession : data.profession,
              hcpSpecialty: data.specialty
            });
    }}}
    }
    setMasterState(item, hco){
      console.log('inside parent');
      console.log(item);
      console.log(hco);

      this.props._SetStateForMainForm.SetStateForMainForm(item,hco);

      this.setState({
        hcpId:item.customer_id,
        hcpName:item.full_name,
        hcpProfession:item.profession,
        hcpSpecialty:item.specialty,
        hco_data:hco
      });
    }


    render(){
        return (
            <div>
            <Card>
                <Card.Header style={{backgroundColor: '#D52B1E', color:'white'}}> 
                  HCP Information
                   {/* <SampleOrderHCPSearch setParentState={this.setMasterState}/>  */}
                </Card.Header>
                <Card.Body>
                <Form id= "hcpInfo">
                  
                      
                 <Form.Group as={Row} controlId = "hcpsearch">
                     <Col>
                     <SampleOrderHCPSearch setParentState={this.setMasterState}/>
                     </Col>
                     </Form.Group>
                 
                  
                    <Form.Group as={Row} controlId = "hcpId">
                    <Col>
                    <Form.Label column > HCP Id </Form.Label>
                    </Col>
                      <Col xs="auto">
                      <Form.Control  readOnly value= {this.state.hcpId} type="text" />{"  "}
                      </Col>
                 
                    {/* </Form.Group>{"  "} */}

                    {/* <Form.Group as={Row} controlId = "hcpName"> */}
                    <Col >
                    <Form.Label column > HCP Name </Form.Label>
                    </Col>
                      <Col xs="auto">
                      <Form.Control  readOnly value= {this.state.hcpName} />
                      </Col>
                    </Form.Group>
                  
                   

                    <Form.Group as={Row} controlId = "hcpProfession">
                    <Col>
                    <Form.Label column > HCP Profession </Form.Label>
                    </Col>
                      <Col xs="auto">
                      <Form.Control className="mb-2"  readOnly placeholder= {this.state.hcpProfession} />
                      </Col>
                      {/* <Col sm="1"></Col> */}
                    {/* </Form.Group>

                    <Form.Group as={Row} controlId = "hcpSpecialty"> */}
                    <Col>
                    <Form.Label column > HCP Specialty </Form.Label>
                    </Col>
                      <Col xs="auto">
                      <Form.Control className="mb-2"  readOnly placeholder= {this.state.hcpSpecialty} />
                      </Col>
                    </Form.Group>
                   
                </Form>
                </Card.Body>
            </Card>
          
            </div>
        );
    }

}