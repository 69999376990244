import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import { sizing } from '@material-ui/system';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  MenuItem,
  TableCell,
  Button,
  FormControl,
  Select,
  Paper,
  Grid,
  TablePagination,
  Modal,
  IconButton,
} from '@material-ui/core';

import TablePaginationActions from '../components/TablePaginationActions';
import AddPackSize from '../components/AddPackSize';

// Constants
var BrandArrayID = 0;
var ProductArrayID = 0;

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    padding: '1% 8% 4% 8%',

    '& > *': {
      //   margin: theme.spacing(1),
      //   width: theme.spacing(16),
      //   height: theme.spacing(16),

      width: '100%',
      height: 'auto',
    },
  },
  labelTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelText: {
    textAlign: 'left',
    font: 'normal normal normal 16px/21px Roboto',
    color: '#333232',
    opacity: '1',
  },
  containerPaper: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    padding: '3%',
    marginBottom: '4%',
  },
  depotSubTitle: {
    margin: '0% 0% 2% 0%',
    color: '#333232',
    float: 'left',
  },
  table: {
    minWidth: 700,
  },
  depotButtonTypo: {
    float: 'right',
  },
  textFieldMaxWidth: {
    width: '100% !important',
  },
  containerMargin: {
    marginBottom: '5px',
  },
  depotButton: {
    color: '#fff',
    background: '#D52B1E',
    textAlign: 'center',
    '&:hover': {
      background: '#D52B1E',
    },
  },
  table: {
    minWidth: 650,
  },
  selectPadding: {
    margin: '-3px 0px -3px 0px',
  },
}));
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#D52B1E',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const customColumnStyle = { maxWidth: 40 };

//Main
const OrderDetails = (props) => {
  const classes = useStyles();

  //States
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [displayModel, setDisplayModel] = React.useState(false);
  const [brandId, setBrandId] = React.useState(0);
  const [productId, setProductId] = React.useState(0);
  const [update, setUpdate] = React.useState(0);
  // Functions/Events
  const fetchFormData = (formType) => {
    return;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleProductSelection = (event, id, brandArrayID) => {
    //setBrandId(brandArrayID);
    //setProductId(event.target.value - 1);
    console.log(brandArrayID);
    console.log(event.target.value - 1);
    //BrandArrayID = brandArrayID;
    //ProductArrayID = event.target.value - 1;
    setBrandId(brandArrayID);
    setProductId(event.target.value - 1);

    console.log(brandId);
    console.log(productId);
    setDisplayModel(true);

    //document.getElementById('brandSelect' + id);
  };
  const handleModelOpen = () => {
    setDisplayModel(true);
  };

  const handleModelClose = () => {
    setDisplayModel(false);
  };

  // Output
  return (
    <div>
      <h4>Order Details</h4>
      <Paper elevation={4} className={classes.containerPaper}>
        {/* <p id="product-description">donde esta la biblioteca</p> */}
        <Grid container className={classes.containerMargin}>
          <Grid container item spacing={1} xs={6}></Grid>
          <Grid
            container
            item
            spacing={1}
            xs={6}
            direction="row-reverse"
            justify="flex-start"
            className={classes.containerRightPadding}
          >
            Total Order Products:{props.TotalCount}
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Product Name</StyledTableCell>
                <StyledTableCell align="center">Pack Size</StyledTableCell>
                <StyledTableCell align="center">Quantity</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.TotalCount > 0 ? (
                (rowsPerPage > 0
                  ? props.Orders.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : props.Orders
                ).map((order, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell align="center">
                      {order.Name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {order.Packsize}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {order.Quantity}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <TableRow className={classes.labelTextContainer}>
                  <TableCell>No Products added yet.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={props.TotalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableContainer>
      </Paper>
    </div>
  );
};

// Exports
export default OrderDetails;
