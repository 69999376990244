import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, Popover, Overlay,Form, Row, Col,Card,InputGroup, ListGroup, OverlayTrigger,
    FormControl, Tooltip, Table, Pagination } from 'react-bootstrap';
//import Card from 'react-bootstrap/Card'
//import {Popover} from 'react-bootstrap/Popover';
import {connect} from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
//import {Request, defaults} from 'request';
import axios from 'axios';

export default class OrderList extends  React.Component
{
    constructor(props)
    {
        super(props);
        this.state ={
            productList:[]
        };
        this.InitialiseStateFromProps=this.InitialiseStateFromProps.bind(this);
       
    }
    componentDidMount(){
        console.log('------------componentDidMount-----Order-----');
       // this.InitialiseStateFromProps();

    }
    InitialiseStateFromProps(){
        console.log('------------InitialiseStateFromProps-----Order-----');
        if(this.props!=null && this.props!=undefined){
            if(this.props.OrderData!=null && this.props.OrderData!=undefined){
                let data = this.props.OrderData;
                this.setState({
                    productList:data
                });
            }
        }
    }
    render(){
        var {productList} = this.props.OrderData;
        return(
            <div>
                <Card>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Product Name</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                productList.map((item,id)=>{
                                    return(
                                        <tr>
                                            <td>{item.name}</td>
                                    <td>{item.size}</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                </Card>
            </div>
        );
    }
}