import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, Popover, Overlay,Form, Row, Col,Card,InputGroup, ListGroup, OverlayTrigger,
    FormControl, Tooltip, Table, Pagination } from 'react-bootstrap';
//import Card from 'react-bootstrap/Card'
//import {Popover} from 'react-bootstrap/Popover';
import {connect} from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
//import {Request, defaults} from 'request';
import Order from './SampleOrderOrder';
import axios from 'axios';


export default class Product extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state={
            products:[],
            orderList:[],
            specialty:'',
            filteredProducts:[],
            firstPageTableList:[],
            paginationCount:1,
            count:0,
            searchQuery:'',
            show:false,
            searchList:[],
            selectedOrdersList:[],
            greenBarCompletionCheck:false
        };

        this.GetAllProducts=this.GetAllProducts.bind(this);
        this.SetStateCustom=this.SetStateCustom.bind(this);
        this.StateUpdateForRowGeneration=this.StateUpdateForRowGeneration.bind(this);
        this.handleSearch=this.handleSearch.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.addProduct=this.addProduct.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.removeProduct=this.removeProduct.bind(this);
        
        this.GetAllProducts();
    }
    GetAllProducts(){
        if(this.props!=null && this.props!=undefined){
            if(this.props.ProductData!=null && this.props.ProductData!=undefined){
                let data = this.props.ProductData; 
                let specialty= data.hcp.specialty;
                
                let countryCode = localStorage.getItem('CountryCode');
                
                if(data.filteredProductListMain.length>0 && data.productListMain.length>0){
                    console.log('--------------DATA Pass Main Product info basc');
                    this.setState({
                        products:data.productListMain,
                        filteredProducts:data.filteredProductListMain,                        
                    });
                    if(data.selectedOrders.length>0){
                        console.log('--------------DATA Pass Selected Orders info basc');
                        this.setState({
                            selectedOrdersList:   data.selectedOrders            
                        });
                    }
                }else{
                // /orderservice/orderProductItem  /GetHCPProducts/:countryCode/:specialty
                let url = '/orderservice/orderProductItem/GetHCPProducts/'+countryCode+'/'+specialty;
                let dataS = axios.get(url).then((res)=> {
                    this.SetStateCustom(res.data, specialty)
                    }
                ); 
            }   
        }}
    }
    SetStateCustom(data, _specialty){
        console.log('-----------SetStateCustom-------------Products ');
        console.log(data);
        let objectData= JSON.parse(data);
        this.setState({
            products:objectData,
            specialty:_specialty,
        });
       this.StateUpdateForRowGeneration();
    }
    StateUpdateForRowGeneration(){
        console.log('-----------------Products StateUpdateForRowGeneration'); 
        console.log(this.state.products);            
        if(this.state.products!=null&&this.state.products!=undefined&&this.state.products.length>0){               
            let tempProdList = this.state.products;                
            let tempFilteredList=[];                
            for(let i=0;i<tempProdList.length;i++){                  
                let itemName = tempProdList[i].product_name;                   
                let packs =  tempProdList[i].quantities;
                let materialNumber = tempProdList[i].material_no;                      
                for(let j=0;j<packs.length;j++){
                    let pack = {
                        name:itemName,
                        size: packs[j],
                        material: materialNumber
                    };
                    tempFilteredList.push(pack) ;                      
                }                       
            }
            let tempfirstPageList=[];
            for(let k=0;k<tempFilteredList.length;k++){
                tempfirstPageList.push(tempFilteredList[k]);
                if(k>4){
                    break;
                }
            }
            let count=  Math.floor(tempFilteredList.length/5);
            this.setState({
                filteredProducts:tempFilteredList,
                firstPageTableList:tempfirstPageList,
                paginationCount:count
            }); 
            this.props.ProductData._SetProductDataMainForm(tempProdList,tempFilteredList,
                this.state.selectedOrdersList);                
        }        
    }
    componentDidMount(){     
        console.log('-----------------Products Copmponenet Did muount'); 
        console.log(this.state.products);
        if(this.props!=null && this.props!=undefined){
            if(this.props.ProductData!=null && this.props.ProductData!=undefined){
                let data = this.props.ProductData; 
                let specialty= data.hcp.specialty;
                
                let countryCode = localStorage.getItem('CountryCode');
                
                if(data.filteredProductListMain.length>0 && data.productListMain.length>0){
                    console.log('-----componentDidMount---------DATA Pass Main Product info basc');
                    this.setState({
                        products:data.productListMain,
                        filteredProducts:data.filteredProductListMain,                        
                    });
                    if(data.selectedOrders.length>0){
                        console.log('--componentDidMount------------DATA Pass Selected Orders info basc');
                        this.setState({
                            selectedOrdersList:   data.selectedOrders            
                        });
                    }
                }
    }}

        if(this.state.products!=null&&this.state.products!=undefined&&this.state.products.length>0){               
            let tempProdList = this.state.products;                
            let tempFilteredList=[];                
            for(let i=0;i<tempProdList.length;i++){                  
                let itemName = tempProdList[i].product_name;                   
                let packs =  tempProdList[i].quantities;
                let materialNumber = tempProdList[i].material_no;                      
                for(let j=0;j<packs.length;j++){
                    let pack = {
                        name:itemName,
                        size: packs[j],
                        material: materialNumber
                    };
                    tempFilteredList.push(pack) ;                      
                }                       
            }
            let tempfirstPageList=[];
            for(let k=0;k<tempFilteredList.length;k++){
                tempfirstPageList.push(tempFilteredList[k]);
                if(k>4){
                    break;
                }
            }
            let count=  Math.floor(tempFilteredList.length/5);
            this.setState({
                filteredProducts:tempFilteredList,
                firstPageTableList:tempfirstPageList,
                paginationCount:count
            }); 
            this.props.ProductData._SetProductDataMainForm(tempProdList,tempFilteredList);                
        }        
    }
    pageChange(value){
        let x = value+1;
        let finalValue = x*5;
        let startValue= value*5;
        let tempList = this.state.filteredProducts;
        let newTempList=[];
        for(let i=startValue;i<finalValue;i++){
            newTempList.push(tempList[i]);
        }
        this.setState({
            firstPageTableList:newTempList
        });
    }
    handleChange(e){
        this.setState({
            searchQuery:e.target.value
        });
    }
    handleSearch(){

        let searchText=  (this.state.searchQuery).toUpperCase();
        let filterListTemp = this.state.filteredProducts;
        let tempsearchList=[];
        for(let i=0;i<filterListTemp.length;i++){
            let modifiedName =  (filterListTemp[i].name).toUpperCase();
            let check = modifiedName.includes(searchText);
            if(check){
                tempsearchList.push(filterListTemp[i]);
            }
        }
        this.setState({
            searchList:tempsearchList,
            show:true
        });
    }
    addProduct(item){
        let tempList= this.state.selectedOrdersList;
        tempList.push(item);
        this.setState({
            selectedOrdersList:tempList,
            greenBarCompletionCheck:true
        });
    }
    removeProduct(item,id){
        let list = this.state.selectedOrdersList;
        list.splice(id,1);
        this.setState({
            selectedOrdersList:list
        });
    }
    handleClose(){
        this.setState({
            show:false
        });
    }
    render(){
        var {specialty, filteredProducts, firstPageTableList, paginationCount,show,searchList,selectedOrdersList} = this.state;
        var _OrderData ={
            _list:this.state.selectedOrdersList
        };
        return(
            <div>
                <Card>
                    <Card.Header style={{backgroundColor: '#D52B1E', color:'white'}}>
                        Available Products, {specialty}
                    </Card.Header>
                    <Card.Body>
                    <InputGroup>
                     <FormControl placeholder= "Search"  id= "searchQuery" onChange={this.handleChange}
                        />
              
                    <InputGroup.Append>
                    <Button variant="outline-danger" onClick={this.handleSearch}>Search</Button>{' '}
                    </InputGroup.Append>
                    </InputGroup>

                    <Table striped bordered hover>
                        <thead>
                            <tr>
                            <th>Product Name</th>
                            <th>Pack Size</th>
                            <th>Add</th>
                            </tr>
                        </thead>
                        <tbody>
                            {firstPageTableList.map((value,id)=>{
                                if(value!=null && value!=undefined){
                                return (
                                    <tr>
                                        <td>
                                            {value.name}
                                        </td>
                                <td>{value.size}</td>
                                <td> <Button variant="outline-danger" 
                 onClick={()=> this.addProduct(value)}>
                  Select</Button></td>
                                    </tr>
                                );}
                            })}
                        </tbody>

                    </Table>
                    <Card.Footer>
                            <Pagination>
                            {
                                firstPageTableList.map((page,id)=>{
                                    if(id<=paginationCount){
                                    return (
                                        
                                        <Pagination.Item onClick={()=> {this.pageChange(id)}}>
                                            {id}
                                        </Pagination.Item>
                                    );
                                }
                                })
                            }
                            </Pagination>
                            </Card.Footer>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header style={{backgroundColor: '#D52B1E', color:'white'}}>Order List</Card.Header>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Product Name</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                selectedOrdersList.map((item,id)=>{
                                    return(
                                        <tr>
                                            <td>{item.name}</td>
                                    <td>{item.size}</td>
                                    <td><Button variant="outline-danger" 
                 onClick={()=> this.removeProduct(item,id)}>
                  Remove</Button>
                  </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                </Card>
                <Modal show={show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>HCP List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <Table>
             <thead>
                 <tr>
                     <th>Product Name</th>
                     <th>Product Size</th>
                     <th>Add</th>
                 </tr>
             </thead>
             <tbody>
            {searchList.map((item,i)=>{
              return(
                  <tr>
                      <td> {item.name}</td>
              <td>{item.size}</td>
              <td><Button variant="outline-danger" 
                 onClick={()=> this.addProduct(item)}>
                  Select</Button>
                  </td>
               
                </tr>
              );
            })}
            </tbody>
          </Table>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
            </div>
        );
    }

}




