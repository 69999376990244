import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, Table,Popover, Overlay,Form, Row, Col,Card,InputGroup, ListGroup, OverlayTrigger,FormControl, Tooltip } from 'react-bootstrap';
//import Card from 'react-bootstrap/Card'
//import {Popover} from 'react-bootstrap/Popover';
import {connect} from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
//import {Request, defaults} from 'request';
import axios from 'axios';

const Popovers = React.forwardRef((props, ref)=>(
    
    <Popover id="popover-basic" ref={ref}>
      <Popover.Title as="h3">Popover right</Popover.Title>
      <Popover.Content>
        And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
  ));
const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Popover right</Popover.Title>
      <Popover.Content>
        And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
  );
class RenderToolTipC extends React.Component
{
    constructor(props)
    {
        super(props);
    }
    render(){
        var {dataFromParent}= this.props;
        console.log('----------------------'+dataFromParent);
        return(
             dataFromParent!=undefined? 
            (<ListGroup>
            {dataFromParent.hcpinfo.map(function(hcp,idx){
                return(
                <ListGroup.Item>{hcp.customer_id}</ListGroup.Item>
                );
            })}
        </ListGroup>)
        :( 
        // <Tooltip id="button-tooltip" >
        //     <ListGroup>
        //         <ListGroup.Item>No search result</ListGroup.Item>
        //         </ListGroup>
        //         </Tooltip>)
        <Popover id="popover-basic" {...props}>
    <Popover.Title as="h3">Quick Search Result</Popover.Title>
    <Popover.Content>
      <ListGroup>
          <ListGroup.Item>No Search Result</ListGroup.Item>
          <ListGroup.Item>No Search Result2</ListGroup.Item>
      </ListGroup>
    </Popover.Content>
  </Popover>)
                
                );
    }
}

export default class OverLays extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state={
            inputJson : '',
            apiURL: '/OrderService/HCP/SearchHCP/', //:searchParam/:countryCode
            searchParam:'',
            hcpId:'',
            hcpName:'',
            hcpInfo:[],
            test:'VNS',
            countryCodehcpPage:'',
            show:false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch=this.handleSearch.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.handleListSelect=this.handleListSelect.bind(this);
      //  this.setState({countryCodehcpPage: localStorage.getItem('CountryCode')})       
    }
   

   handleClick (event)  {
    setShow(!show);
    setTarget(event.target);
  };
    handleChange(e)
    {    
        console.log('---------------------'+e.target.value);
        this.setState({
            searchParam : e.target.value
        });
        
    }
    

    GetHCPData(url){   
     try{
       
      let awaitData =  axios.get(url).then((res)=> {
        console.log('then axios');
        console.log(res);
        this.SetHCPInfo(res.data);
        return res.data;
      }).catch((err)=>console.log(err));      
    }catch(err){
      console.log(err);   
    }   
  }
    SetHCPInfo(arrayHCP){
      console.log(arrayHCP);
        let dataJson= JSON.parse(arrayHCP);
        let tempArray=[];
        for(let i=0;i<dataJson.length; i++){
                tempArray.push(dataJson[i]);          
        }
        console.log('-----------------ArrayHCP');
        console.log(tempArray);
        this.setState({
            hcpInfo:tempArray
        });
    }
     handleSearch(){
      this.setState({
        show:true
      });
      let countryCode =localStorage.getItem('countryCode');
        let hcpURI= this.state.apiURL+ this.state.searchParam+'/'+countryCode;

        let Pro = new Promise((resolve, reject)=>{
          try{
          this.GetHCPData(hcpURI);
          resolve();
          }catch(err){
            reject();
          }
        });
        Pro.then(()=> console.log('-----------------Get hcp promoise success')).
        catch((err)=>{console.log(err);
        console.log('-----------------Get hcp promoise fail')
        });
        console.log('-------------after get hcp promise');

    }
    handleClose(){
      this.setState({
        show:false
      });
    }
    handleListSelect(item, hco)
    {
      this.props.setParentState(item, hco);
      this.setState({
        hcpId:item.customer_id,
        hcpName:item.full_name,
        show:false
      });
    }
    //<RenderToolTipC hcpinfo ={ hcpinfor} />
           
//<RenderToolTipC hcpinfo = {this.state.hcpInfo}/>
    render(){
        var ref = React.createRef();
        var hcpinfor = {
            info:this.state.hcpInfo,
            ref:ref
        }
        var {hcpInfo, show} = this.state;
        console.log('--------------------below render'+hcpInfo);
        console.log(hcpinfor);
        return(
            <div>
            {/* <OverlayTrigger trigger="click" placement='bottom'
        
       
            overlay={
                <Popover id="popover-basic" >
                <Popover.Title as="h3">HCPs Results</Popover.Title>
                <Popover.Content>
                <ListGroup>
             {   hcpInfo.map(function(hcp,idx)
           //  function()
                {
                  console.log('----------------List Render');
               //   console.log(this.state.hcpInfo);
                  console.log('----------hcp-----'+hcp);
                  console.log('----------hcp-----'+hcp.length);
                  console.log('---------idx------'+idx);
                if(hcp.length>0){
                return(
                <ListGroup.Item key={idx}>{hcp.customer_id}</ListGroup.Item>
                );}else{
                    return( 
                        <ListGroup.Item>No Search Result</ListGroup.Item>                       
                   );
                }
            })}
        </ListGroup>
                
                </Popover.Content>
              </Popover>

            }  > */}

            <InputGroup>
            <FormControl placeholder= "Search"  id= "searchQuery" onChange={this.handleChange}
            />
              
              <InputGroup.Append>
              <Button variant="outline-danger" onClick={this.handleSearch}>Search</Button>{' '}
              </InputGroup.Append>
            </InputGroup>
            {/* </OverlayTrigger> */}
            <Modal show={show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>HCP List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr><th>hcpID</th>
              <th>HCP Name</th>
              <th>Specialty</th>
              <th>Select</th></tr>
            </thead>
            <tbody>       
        
            {hcpInfo.map((item,i)=>{
              return(
                <tr>
                  <td>{item.customer_id}</td>
                  <td>{item.full_name}</td>
                  <td>{item.specialty}</td>
                  <td><Button variant="outline-danger" 
                 onClick={()=> this.handleListSelect(item, item.HCO_Data)}>
                  Select</Button>
                  </td>
                  </tr>
              );
            })}
         
          </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
              </div>
        );
    }
}












