import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, Popover, Overlay,Dropdown,Form, Row, Col,Card,InputGroup, ListGroup, OverlayTrigger,FormControl, Tooltip } from 'react-bootstrap';
//import Card from 'react-bootstrap/Card'
//import {Popover} from 'react-bootstrap/Popover';
import {connect} from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
//import {Request, defaults} from 'request';
import axios from 'axios';
import Instructions from './SampleOrderDeliveryInstructions';

export default class DeliveryIndia extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state ={
            deliveryInstruction:'',
            approvers:[],
            depots :[],
            businessUnits:[],
            cfaNames:[],
            approver:'',
            depot :'',
            businessUnit:'',
            cfaName:'',
            
        };
      this.SelectApprover=this.SelectApprover.bind(this);
    }
    SelectApprover(item){
        this.setState({
            approver:item.target.value
        });
    }
    EnterDeliveryInstruction(e){
        this.setState({
            deliveryInstruction:e.target.value
        });
    }
    SelectBusinessUnit(item){
        this.setState({
            depot:item.target.value
        });
    }
    SelectDepots(item){
        this.setState({
            businessUnit:item.target.value
        });
    }
    SelectCFANames(item){
        this.setState({
            cfaName:item.target.value
        });
    }
   
    render(){
        var {approvers,depots, businessUnits, cfaNames} = this.state;
        return(
            <Card>
            <Card.Body>
                <Form id="deliverAddressIndia">
                <Form.Row>
                <Instructions masterFunction={this.props.HCOInformation}/>
                    </Form.Row>

                   

                    <Form.Row>

                    <Form.Group as={Row} controlId = "approvers">
                    <Form.Label column xs="auto"> Approvers </Form.Label>
                      <Col xs="auto">
                          <Dropdown>
                          <Dropdown.Item>No Approvers available</Dropdown.Item>
                              {approvers.map(function(item, id){
                                  return(
                                      <Dropdown.Item eventKey={item} onClick ={this.SelectApprover}>
                                          {item}
                                      </Dropdown.Item>
                                  );
                              })}
                          </Dropdown>
                       </Col>
                      <Col sm="1"></Col>
                    </Form.Group>{"  "}

                    <Form.Group as={Row} controlId = "depot">
                    <Form.Label column xs="auto"> Depot </Form.Label>
                      <Col xs="auto">
                      <Dropdown>
                      <Dropdown.Item>No Depot available</Dropdown.Item>
                              {depots.map(function(item, id){
                                  return(
                                      <Dropdown.Item eventKey={item} onClick ={this.SelectDepots}>
                                          {item}
                                      </Dropdown.Item>
                                  );
                              })}
                          </Dropdown>
                      </Col>
                      <Col sm="1"></Col>
                    </Form.Group>{"  "}
                    
                    </Form.Row>

                    <Form.Row>

                    <Form.Group as={Row} controlId = "businessUnit">
                    <Form.Label column xs="auto"> Business Unit </Form.Label>
                    <Col xs="auto">
                    <Dropdown>
                        <Dropdown.Item>No BU available</Dropdown.Item>
                              {businessUnits.map(function(item, id){
                                  return(
                                      <Dropdown.Item eventKey={item} onClick ={this.SelectBusinessUnit}>
                                          {item}
                                      </Dropdown.Item>
                                  );
                              })}
                          </Dropdown>
                    </Col>
                    <Col sm="1"></Col>
                    </Form.Group>{"  "}

                    <Form.Group as={Row} controlId = "cfaName">
                    <Form.Label column xs="auto"> CFA Name </Form.Label>
                    <Col xs="auto">
                    <Dropdown>
                    <Dropdown.Item>No Approvers available</Dropdown.Item>
                              {cfaNames.map(function(item, id){
                                  return(
                                      <Dropdown.Item eventKey={item} onClick ={this.SelectCFANames}>
                                          {item}
                                      </Dropdown.Item>
                                  );
                              })}
                          </Dropdown>
                    </Col>
                    <Col sm="1"></Col>
                    </Form.Group>{"  "}

                    </Form.Row>
                </Form>
            </Card.Body>
            </Card>
        );
    }
}








