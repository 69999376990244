import React from "react";
import ReactDOM from "react-dom";
import {
  Modal,
  Button,
  Popover,
  Overlay,
  Form,
  Row,
  Col,
  Card,
  Pagination,
} from "react-bootstrap";
//import Card from 'react-bootstrap/Card'
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
//import {Request, defaults} from 'request';
import axios from "axios";
// import SampleOrderOnline from '../../components/pages/';
// import SampleOrderHCPInfo from '../components/SampleOrderHCPInfo';
import Delivery from "../components/SampleOrderDelivery";
import DeliveryIndia from "../components/SampleOrderDeliveryIndia";
import Product from "../components/SampleOrderProduct";
//import {SalesRepModel, OrderDetails} from '../../../server/ServiceModels/SalesRepOnlineModel';

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 5,
      border: "none",
      display: "block",
      width: "50%",
    }}
  />
);
class Area {
  constructor(_height, _width, _sales) {
    this.area = _height * _width;
    this.perimeter = (_height + _width) * 2;
    this.sales = _sales;
    this.salesClass = new SalesModel(3, 4);
  }
}
class SalesModel {
  constructor(_height, _weight) {
    this.height = _height;
    this.weight = _weight;
  }
}
export default class SalesRepFormMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hcp: [],
      hco: [],
      items: [1, 2, 3],
      hidehcp: true,
      hideDelivery: false,
      hideLilly: true,
      hideDeliveryIndia: false,
      hideProduct: false,
      ProductDataMain: [],
      ProductDataFIltered: [],
      selectedOrderList: [],
      currentPageNumber: 1,
      bar1: "red",
      bar2: "red",
      submitButtonSHow: false,
      orderInfo: [],
      deliveryInstructions: "",
      affiliateConfiguration: {},
    };
    this.SetStateForMainForm = this.SetStateForMainForm.bind(this);
    this.SetStateHCP = this.SetStateHCP.bind(this);
    this.SetStateDelivery = this.SetStateDelivery.bind(this);
    this.SetProductDataMainForm = this.SetProductDataMainForm.bind(this);
    this.SetStateProducts = this.SetStateProducts.bind(this);
    this.proceedCLick = this.proceedCLick.bind(this);
    this.SetDeliveryInstructions = this.SetDeliveryInstructions.bind(this);

    this.submit = this.submit.bind(this);
  }
  SetAffiliateConfiguration(data) {
    let affiliateObject = JSON.parse(data);
    this.setState({
      affiliateConfiguration: affiliateObject,
    });
    let area = new Area(1, 2, new SalesModel(3, 4));
  }
  SetProductDataMainForm(_products, _productsFiltered, _selectedOrders) {
    this.setState({
      ProductDataMain: _products,
      ProductDataFIltered: _productsFiltered,
      selectedOrderList: _selectedOrders,
    });
  }
  SetStateForMainForm(_hcp, _hco) {
    this.setState({
      hcp: _hcp,
      hco: _hco,
    });
  }
  SetStateProducts() {
    this.setState({
      hidehcp: false,
      hideLilly: false,
      hideDelivery: false,
      hideDeliveryIndia: false,
      hideProduct: true,
      currentPageNumber: 2,
      bar1: "green",
    });
  }
  SetStateHCP() {
    this.setState({
      hidehcp: false,
      hideLilly: false,
      hideDelivery: true,
      hideDeliveryIndia: true,
      hideProduct: false,
      bar2: "green",
      submitButtonSHow: true,
    });
  }
  SetStateDelivery() {
    this.setState({
      hideDelivery: false,
      hideDeliveryIndia: false,
      hidehcp: true,
      hideLilly: true,
      hideProduct: false,
      currentPageNumber: 1,
    });
  }
  SetDeliveryInstructions(instructions) {
    this.setState({
      deliveryInstructions: instructions,
    });
  }
  submit() {
    try {
      axios
        .get("http://localhost:5001/adminService/Roles/all", {
          //  headers : {
          //    'Content-Security-Policy' : 'connect-src http://localhost:5001/'
          //  }
          crossdomain: true,
        })
        .then((data) => console.log(data));
    } catch (err) {
      console.log(err);
    }
    // let formData = new SalesRepModel();
    // formData.hcoDetails=this.state.hco;
    // formData.hcpDetails = this.state.hcp;
    // formData.orderDetails=this.state.orderInfo;
    // formData.selectedProducts=this.state.selectedOrderList;
    // formData.deliveryInstructions= this.state.deliveryInstructions;
    // formData.affiliateCOnfiguration = this.state.affiliateConfiguration;
    // axios.post('/OrderService/SalesRepOnline/save', formData);
  }
  proceedCLick() {
    let currentState = this.state.currentPageNumber;
    if (currentState == 1) {
      this.SetStateProducts();
    }
    if (currentState == 2) {
      this.SetStateHCP();
    }
  }
  OrderLillyInfo(affiliateId, username, countryCode, lillyId, userId, orderId) {
    let data = {
      _affiliateId: affiliateId,
      _username: username,
      _countryCode: countryCode,
      _lillyId: lillyId,
      _userId: userId,
      _orderId: orderId,
    };
    // Make axios API call here for the affiliate configuration
    axios
      .get(
        "/OrderService/AffiliateConfiguration/GetFormConfiguration/" +
          countryCode
      )
      .then((response) => {
        this.SetAffiliateConfiguration(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      orderInfo: data,
    });
  }
  render() {
    var {
      items,
      hidehcp,
      hideLilly,
      hideDeliveryIndia,
      hideDelivery,
      hideProduct,
      bar1,
      bar2,
      submitButtonSHow,
    } = this.state;
    var dataForHCPInfoComponenet = {
      hcp: this.state.hcp,
      SetStateForMainForm: this.SetStateForMainForm,
    };
    var _ProductData = {
      hcp: this.state.hcp,
      _SetProductDataMainForm: this.SetProductDataMainForm,
      selectedOrders: this.state.selectedOrderList,
      filteredProductListMain: this.state.ProductDataFIltered,
      productListMain: this.state.ProductDataMain,
    };
    var DeliverIndiaProp = {
      hco: this.state.hco,
      _SetDeliveryInstructions: this.SetDeliveryInstructions,
    };
    var DeliveryAuProp = {
      hco: this.state.hco,
      _SetDeliveryInstructions: this.SetDeliveryInstructions,
    };
    return (
      <div>
        <Card>
          <Card.Body>
            <Pagination className='justify-content-md-center'>
              <Pagination.Item onClick={this.SetStateDelivery}>
                {1}
              </Pagination.Item>
              <ColoredLine color={bar1} />
              <Pagination.Item onClick={this.SetStateProducts}>
                {2}
              </Pagination.Item>
              <ColoredLine color={bar2} />
              <Pagination.Item onClick={this.SetStateHCP}>{3}</Pagination.Item>
            </Pagination>
          </Card.Body>
        </Card>
        {hideLilly && (
          <SampleOrderOnline OrderLillyInfo={this.OrderLillyInfo} />
        )}
        {hidehcp && (
          <SampleOrderHCPInfo _SetStateForMainForm={dataForHCPInfoComponenet} />
        )}
        {hideDeliveryIndia && (
          <DeliveryIndia HCOInformation={DeliverIndiaProp} />
        )}
        {hideDelivery && <Delivery HCOInformation={DeliveryAuProp} />}
        {hideProduct && <Product ProductData={_ProductData} />}

        <Card>
          <Card.Body>
            <Form>
              <Form.Row className='justify-content-md-center'>
                <Col xs='auto'>
                  <Button variant='outline-danger'>Withdraw</Button>{" "}
                </Col>

                <Col xs='auto'>
                  <Button variant='outline-danger'>Save</Button>{" "}
                </Col>

                <Col xs='auto'>
                  <Button variant='outline-danger' onClick={this.proceedCLick}>
                    Proceed
                  </Button>{" "}
                </Col>
                {submitButtonSHow && (
                  <Col xs='auto'>
                    <Button variant='outline-danger' onClick={this.submit}>
                      Submit
                    </Button>{" "}
                  </Col>
                )}
              </Form.Row>

              {/* <Form.Group as={Row}  srOnly>
                        <Col>
                      {false && <Form.Control  value={this.state.hcp} srOnly />}
                        </Col><Col>
                        {false &&    <Form.Control  value={this.state.hco} srOnly/>} </Col><Col>
                        {false &&   <Form.Control  value={this.state.selectedOrderList} srOnly/> }</Col><Col>
                        {false &&   <Form.Control  value={this.state.hcp} srOnly/>}
                        </Col>
                    </Form.Group> */}
            </Form>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
