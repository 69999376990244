import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, Popover, Overlay,Form, Row, Col,Card } from 'react-bootstrap';
//import Card from 'react-bootstrap/Card'
import {connect} from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
//import {Request, defaults} from 'request';
import axios from 'axios';


export default class SampleOrderOnline extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            lillyEmail:'',
            orderId:'1',
            lillyContactId:'',
            lillyCOntactName:'',
            DSM:'',
            affiliateId:'',
            affiliateCode :'',
            userId:0
        };
        localStorage.setItem('countryId', '1');
        localStorage.setItem('countryCode', 'AU');
        localStorage.setItem('lillyId', 'c261027');
        localStorage.setItem('userName', 'Vashishtha Srivastava');
        localStorage.setItem('userEmail', 'srivastava_vashishtha@network.lilly.com');
        localStorage.setItem('userId', 'c261027');
        let UserId = localStorage.getItem('userId');
        this.getCurrentUserSession(UserId);

    }


    getCurrentUserSession(userId){
        let requestObject = axios.create();
        let url = '/OrderService/CurrentSalesRep/'+userId;       
           
            let dataS = axios.get(url).then((res)=> {
                this.SetStateCustom(res.data)
                }
            );
            
       
        
    }
    SetStateCustom(dataJson){
        let data= JSON.parse(dataJson);
        let countryCode='';
        console.log(data);
        if(data.affiliate_id == 1){
            countryCode='AU';
        }
        else{
            countryCode='IN';
        }
        this.setState({
            affiliateId : data.affiliate_id,
            lillyCOntactName :data.user_name,
            affiliateCode: countryCode,
            lillyContactId: data.lilly_id,
            userId:data.user_id
        });
        this.props.OrderLillyInfo(data.affiliate_id, data.user_name,data.lilly_id, countryCode,data.user_id
            , this.state.orderId  );
        localStorage.setItem('CountryCode',countryCode);
    }
    render(){
        return(
           
            <Card>
                 <Card.Header style={{backgroundColor: '#D52B1E', color:'white'}}>Lilly Information
                 </Card.Header>
                <Card.Body>
          <Form id ="LillyInformation">
                
                    
              <Form.Group as={Row}  controlId = "orderFormId">
              <Col>
                <Form.Label column >Order Form Id  </Form.Label>
                </Col>
                <Col >
                <Form.Control column  readOnly value= {this.state.orderId} /> 
                </Col>  
               
              </Form.Group>
              

            
              <Form.Group as={Row}  controlId = "signedOrderFormAttachment">
              <Col>
                <Form.Label column > Signed Order Form Attachment </Form.Label>
                </Col>
                <Col xs="auto">
                <Form.File id="signedAttachmentId" label ="Signed Order Form Attachment" custom column ></Form.File>
                </Col>  
               
              </Form.Group>
              
             
              <Form.Group   as={Row} controlId = "LillyContactId">
              <Col >
                <Form.Label column xs="auto" > Lilly Contact's Id </Form.Label>
                </Col>
                <Col xs="auto">
                <Form.Control column  readOnly value= {this.state.lillyContactId} />
                </Col>
                {/* <Col sm="2"></Col> */}
                {/* </Form.Group> */}
                {/* <Form.Group  as={Row}  controlId = "LillyContactName"> */}
                <Col>
                <Form.Label column xs="auto"> Lilly Contact's Name </Form.Label>
                </Col>
                <Col xs="auto">
                <Form.Control column    readOnly value= {this.state.lillyCOntactName+this.state.lillyCOntactName} />     
                </Col>
                
              </Form.Group>
            
              {/* <Form.Row> */}
              <Form.Group  as={Row} controlId = "dsm">
           
                  <Col  >
                <Form.Label column >DSM  </Form.Label>
                </Col>
                <Col >
                <Form.Control column  className="mb-2"  readOnly  placeholder= {this.state.lillyContactId} /> 
                
                </Col> 
               
              </Form.Group>
              {/* </Form.Row>   */}

          </Form>
          </Card.Body>
          </Card>

        ); 
    }
}


