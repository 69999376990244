import React from 'react';
import PropTypes from 'prop-types';

const footerStyle = (props) => ({
  position: 'absolute',
  left: 0,
  bottom: 0,
  height: props.height,
  borderTop: '1px solid #e7e7e7',
  backgroundColor: '#f8f8f8',
  textAlign: 'center',
  paddingTop: '10px',
  width: '100%',
});

const Footer = (props) => (
  <div></div>
  // <footer style={footerStyle(props)}>
  //   {props.children}
  // </footer>
  );

Footer.propTypes = {
  children: PropTypes.any,
};

export default Footer;
