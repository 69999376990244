import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Nav = (props) => (
  <nav className="navbar navbar-default" style={{backgroundColor: '#D52B1E', color:'white'}}>
    <div className="container-fluid">
      <div className="navbar-header">
        <Link  className="navbar-brand" to="/" style={{color:'white'}}>
        Sample Order Onlines
        </Link>
      </div>
    </div>
  </nav>
);


Nav.propTypes = {
  brandImage: PropTypes.string,
};

export default Nav;
