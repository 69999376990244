/* eslint-disable linebreak-style */
import React from 'react';

import Panel from '../partial/panel';

// this is a page called Demo, it's nested in the Template route in main.js so will
// inherit the Template layout

const Demo = () => {
  return (
    <div>
      <Panel>
        <div>Normal DOM Element</div>
      </Panel>
    </div>
  );
};

export default Demo;
