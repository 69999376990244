import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import { sizing } from '@material-ui/system';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  MenuItem,
  TableCell,
  Button,
  FormControl,
  Select,
  Paper,
  Grid,
  TablePagination,
  Modal,
  IconButton,
} from '@material-ui/core';

import TablePaginationActions from '../components/TablePaginationActions';
import AddPackSize from '../components/AddPackSize';
import OrderDetails from '../components/OrderDetails';

// Constants
var BrandArrayID = 0;
var ProductArrayID = 0;

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    padding: '1% 8% 4% 8%',

    '& > *': {
      //   margin: theme.spacing(1),
      //   width: theme.spacing(16),
      //   height: theme.spacing(16),

      width: '100%',
      height: 'auto',
    },
  },
  labelTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelText: {
    textAlign: 'left',
    font: 'normal normal normal 16px/21px Roboto',
    color: '#333232',
    opacity: '1',
  },
  containerPaper: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    padding: '3%',
    marginBottom: '4%',
  },
  depotSubTitle: {
    margin: '0% 0% 2% 0%',
    color: '#333232',
    float: 'left',
  },
  table: {
    minWidth: 700,
  },
  depotButtonTypo: {
    float: 'right',
  },
  textFieldMaxWidth: {
    width: '100% !important',
  },
  containerMargin: {
    marginBottom: '5px',
  },
  depotButton: {
    color: '#fff',
    background: '#D52B1E',
    textAlign: 'center',
    '&:hover': {
      background: '#D52B1E',
    },
  },
  table: {
    minWidth: 650,
  },
  selectPadding: {
    margin: '-3px 0px -3px 0px',
  },
}));
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#D52B1E',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const customColumnStyle = { maxWidth: 40 };

//Main
const ProductDetails = (props) => {
  const classes = useStyles();

  //States
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [displayModel, setDisplayModel] = React.useState(false);
  const [brandId, setBrandId] = React.useState(0);
  const [productId, setProductId] = React.useState(0);
  const [displayBrands, setDisplayBrands] = React.useState(
    props.Products.Brands
  );
  const [orderDetails, setOrderDetails] = React.useState([]);
  const [update, setUpdate] = React.useState(0);
  // Functions/Events
  const fetchFormData = (formType) => {
    return;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleProductSelection = (event, id, brandArrayID) => {
    //setBrandId(brandArrayID);
    //setProductId(event.target.value - 1);
    // console.log(brandArrayID);
    // console.log(event.target.value - 1);
    //BrandArrayID = brandArrayID;
    //ProductArrayID = event.target.value - 1;
    setBrandId(brandArrayID);
    setProductId(event.target.value - 1);

    // console.log(brandId);
    // console.log(productId);
    setDisplayModel(true);

    //document.getElementById('brandSelect' + id);
  };
  const handleModelOpen = () => {
    setDisplayModel(true);
  };

  const handleModelClose = () => {
    setDisplayModel(false);
  };

  const handleOnSubmitClick = (brandID, ProdID, Quantity) => {
    console.log(brandID, ProdID, Quantity);
    setOrderDetails(
      orderDetails.concat([
        {
          Name: props.Products.Brands[brandID].Name,
          Packsize: props.Products.Brands[brandID].Products[ProdID].Name,
          Quantity: Quantity,
        },
      ])
    );
    handleModelClose();
  };

  // Output
  return (
    <div>
      <h4>Available Products</h4>
      <Paper elevation={4} className={classes.containerPaper}>
        {/* <p id="product-description">donde esta la biblioteca</p> */}
        <Grid container className={classes.containerMargin}>
          <Grid container item spacing={1} xs={6}></Grid>
          <Grid
            container
            item
            spacing={1}
            xs={6}
            direction="row-reverse"
            justify="flex-start"
            className={classes.containerRightPadding}
          >
            Total Products Found:{props.Products.TotalCount}
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Product Name</StyledTableCell>
                <StyledTableCell
                  align="center"
                  size="small"
                  style={customColumnStyle}
                >
                  Add Product
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? displayBrands.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : displayBrands
              ).map((brand, i) => (
                <StyledTableRow key={brand.ID}>
                  <StyledTableCell align="center" component="th" scope="row">
                    {brand.Name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <FormControl
                      margin="dense"
                      className={`${classes.textFieldMaxWidth} ${classes.selectPadding}`}
                    >
                      <Select
                        variant="outlined"
                        defaultValue={0}
                        name={`brandSelect${brand.ID}`}
                        onChange={(event) =>
                          handleProductSelection(event, brand.ID, i)
                        }
                      >
                        <MenuItem key={0} value={0}>
                          Select Pack Size
                        </MenuItem>
                        {brand.Products.map((product, j) => {
                          return (
                            <MenuItem key={product.ID} value={j + 1}>
                              {product.Name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={props.Products.TotalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableContainer>
        <Modal open={displayModel} onClose={handleModelClose}>
          <AddPackSize
            Products={displayBrands}
            brandID={brandId}
            productId={productId}
            quantitySubmitClick={handleOnSubmitClick}
          />
        </Modal>
      </Paper>
      <OrderDetails TotalCount={orderDetails.length} Orders={orderDetails} />
    </div>
  );
};

// Exports
export default ProductDetails;
