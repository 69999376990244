import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, Popover, Overlay,Form, Row, Col,Card,InputGroup, ListGroup, OverlayTrigger,FormControl, Tooltip } from 'react-bootstrap';
//import Card from 'react-bootstrap/Card'
//import {Popover} from 'react-bootstrap/Popover';
import {connect} from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
//import {Request, defaults} from 'request';
import Instructions from './SampleOrderDeliveryInstructions';
import axios from 'axios';

export default class Deliver extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state ={
            emailAddress:'',
            streetAddress:'',
            suburb :'',
            postCode:'',
            phoneNumber:0,  
            stateName:''
        };
        this.SetStateInitial=this.SetStateInitial.bind(this);
       // this.SetStateInitial(props);
    }
    componentDidMount(){
        console.log('----------------------componentDidMount');
        this.SetStateInitial(this.props);
    }
    SetStateInitial(props){
        console.log('----------------------SetStateInitial');
        if(props.HCOInformation.hco!=null && props.HCOInformation.hco!=undefined)
       {
        this.setState({
            emailAddress:props.HCOInformation.hco[0].AddressLine1+props.HCOInformation.hco[0].AddressLine2+
            props.HCOInformation.hco[0].AddressLine3+props.HCOInformation.hco[0].AddressLine4+
            props.HCOInformation.hco[0].AddressLine5,
            streetAddress:props.HCOInformation.hco[0].AddressLine2+
            props.HCOInformation.hco[0].AddressLine3,
            suburb :props.HCOInformation.hco[0].city_name,
            postCode:props.HCOInformation.hco[0].postal_code,
            phoneNumber:props.HCOInformation.hco[0].phone_number,
            stateName:props.HCOInformation.hco[0].city_name
        });
    }
    }
    render(){

        return(
            <Card>
                  <Card.Header style={{backgroundColor: '#D52B1E', color:'white'}}>Delivery Address
                 </Card.Header>
            <Card.Body>
                <Form id="deliverAddress">
               
                <Form.Group as={Row} controlId = "emailAddress">
                    <Col>
                    <Form.Label column > HCP Email Address </Form.Label></Col>
                      <Col xs="auto">
                      <Form.Control  readOnly value= {this.state.emailAddress}  />
                      </Col>
                   
                    </Form.Group>
                   

                    <Form.Group as={Row} controlId = "streetAddress">
                        <Col>
                    <Form.Label column >  Street Address </Form.Label></Col>
                      <Col xs="auto">
                      <Form.Control  readOnly value= {this.state.streetAddress} type="text" />{"  "}
                      </Col>
                   
                    </Form.Group>{"  "}

                   

                    <Form.Group as={Row} controlId = "suburb">
                        <Col>
                    <Form.Label column> Suburb </Form.Label>
                    </Col>
                      <Col xs="auto">
                      <Form.Control  readOnly value= {this.state.suburb} type="text" />{"  "}
                      </Col>
                      {/* <Col sm="1"></Col> */}
                    </Form.Group>{"  "}

                    <Form.Group as={Row} controlId = "posctCode">
                        <Col>
                    <Form.Label column> Post Code </Form.Label>
                    </Col>
                      <Col xs="auto">
                      <Form.Control  readOnly value= {this.state.postCode} type="text" />{"  "}
                      </Col>
                     
                    </Form.Group>{"  "}
                    
                  

                    <Form.Group as={Row} controlId = "phoneNumber">
                        <Col>
                    <Form.Label column > Phone Number </Form.Label>
                    </Col>
                    <Col xs="auto">
                    <Form.Control  readOnly value= {this.state.phoneNumber} type="text" />{"  "}
                    </Col>
                  
                    </Form.Group>{"  "}

                    <Form.Group as={Row} controlId = "State">
                        <Col>
                    <Form.Label column > State </Form.Label>
                    </Col>
                    <Col xs="auto">
                    <Form.Control  readOnly value= {this.state.stateName} type="text" />{"  "}
                    </Col>
                   
                    </Form.Group>{"  "}
                    <Instructions masterFunction={this.props.HCOInformation}/>
                   
                </Form>
            </Card.Body>
            </Card>
        );
    }
}








