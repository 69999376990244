import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { AddCircleOutline } from '@material-ui/icons';

import {
  Card,
  CardContent,
  Paper,
  Checkbox,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  MenuItem,
  Select,
  Button,
  TextField,
  Grid,
} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  baseModal: {
    position: 'absolute',
    top: '20%',
    left: '10%',
    width: '80%',
    height: '60%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #D52B1E',
    borderRadius: '30px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  labelTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelCheckBoxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  labelText: {
    textAlign: 'left !important',
    font: 'normal normal normal 16px/21px Roboto',
    color: '#333232',
    opacity: '1',
  },
  textFieldMaxWidth: {
    width: '100% !important',
  },
  paperMaxWidth: {
    width: '100% !important',
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
  },
  containerMargin: {
    marginBottom: '5px',
  },
  containerRightPadding: {
    paddingRight: '15px',
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#D52B1E',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const customColumnStyle = { maxWidth: 40 };

const AddPackSize = React.forwardRef((props, ref) => {
  const classes = useStyles();

  const [quantity, setQuantity] = React.useState(0);

  const handleSubmit = () => {
    props.quantitySubmitClick(props.brandID, props.productId, quantity);
  };

  return (
    <Card className={classes.baseModal}>
      <CardContent>
        <h3 id="modal-title">Select pack Size</h3>
        {/* <p id="modal-description">donde esta la biblioteca</p> */}
        <Grid container className={classes.containerMargin}>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Product Name</StyledTableCell>
                  <StyledTableCell
                    align="center"
                    size="small"
                    style={customColumnStyle}
                  >
                    Pack Size
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell align="center">
                    <TextField
                      className={classes.textFieldMaxWidth}
                      disabled
                      id="packsize"
                      variant="outlined"
                      value={props.Products[props.brandID].Name}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    size="small"
                    style={customColumnStyle}
                  >
                    <TextField
                      className={classes.textFieldMaxWidth}
                      disabled
                      id="quantity"
                      variant="outlined"
                      value={
                        props.Products[props.brandID].Products[props.productId]
                          .Name
                      }
                    />
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid container className={classes.containerMargin}>
          <Grid item xs={2}>
            <Checkbox
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
          <Grid item xs={7} className={classes.labelTextContainer}>
            <h1 className={classes.labelText} htmlFor="productDetails">
              I confirm I am a registered Health Care Practitioner authorised to
              prescribe the products I have requested, and will be the recipient
              of this order.
            </h1>
          </Grid>
          <Grid container item xs={3}>
            <Grid item xs={12}>
              <label className={classes.labelText} htmlFor="Quantity">
                Quantity
              </label>
            </Grid>
            <Select
              variant="outlined"
              defaultValue={0}
              onChange={(event) => setQuantity(event.target.value)}
            >
              <MenuItem key={0} value={0}>
                Select Quantity
              </MenuItem>
              {props.Products[props.brandID].Products[
                props.productId
              ].Quantities.map((quant, j) => {
                return (
                  <MenuItem key={j + 1} value={quant}>
                    {quant}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row-reverse"
          justify="flex-start"
          className={classes.containerRightPadding}
        >
          <Button size="large" variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
});

export default AddPackSize;
