import React from 'react';
import PropTypes from 'prop-types';

import Nav from './nav';
import Footer from './footer';

// this is our main template component, all routes are rendered at {props.children}

const footerHeight = '45px';

// the bottom margin of the body is the footer height
document.body.style.marginBottom = footerHeight;

const Template = (props) => (
  <div >
    <Nav />

    <div className="container">
      {props.children}
    </div>

    <Footer height={footerHeight}>
			Footer
		</Footer>
  </div>
);

Template.propTypes = {
  children: PropTypes.any,
};

export default Template;
