/* eslint-disable linebreak-style */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link } from 'react-router-dom';

import Panel from '../partial/panel';
import Well from '../partial/well';

// this is a page called Home, it's nested in the Template route in main.js so will
// inherit the Template layout

const Home = () => (
  <div>
    <div className="page-header">
      <h1>
        Cirrus Web Accelerator
        <small>React</small>
      </h1>
    </div>

    <p>This is the react flavour of the Cirrus Web Accelerator.</p>
    <div className="alert alert-warning" role="alert">
      <b>Please note: </b>
      This accelerator is powered by react-router, a powerful, quick routing
      system for React. It is <b>strongly</b> recommended that you read their{' '}
      <a href="https://github.com/ReactTraining/react-router#readme">
        documentation
      </a>
    </div>

    <div className="page-header">
      <h3>Getting started.</h3>
    </div>
    <p>
      To use this flavour of the web accelerator, create your react pages under:{' '}
      <code>src/components/pages</code>
      and import them into: <code>src/components/router.jsx</code>
    </p>

    <div className="alert alert-warning" role="alert">
      <b>Warning: </b>
      This example - and its components - uses bootstrap, to change or extend
      this, modify/add your dependencies in
      <code>src/public/index.html</code>
    </div>

    <Panel>
      <p>
        Hyperlinks (<code>href</code>) in this web accelerator are slightly
        different. Heres the basic usage:
      </p>
      <Well>
        <code>{'<Link to="/demo">I\'m a link</Link>'}</code> -
        <Link to="/demo">I'm a link</Link>
      </Well>
    </Panel>

    <Panel heading="I'm a panel">
      <p>
        This bootstrap panel is abstracted into a React component for
        reusability.
      </p>
      Check out <code>src/components/panel.jsx</code> to see how.
    </Panel>
  </div>
);

export default Home;
