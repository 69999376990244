import React from 'react';
import PropTypes from 'prop-types';

const Well = (props) => (
  <div className="well">
    {props.children}
  </div>
);


Well.propTypes = {
  children: PropTypes.any,
};

export default Well;
