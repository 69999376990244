import React from 'react';
import PropTypes from 'prop-types';

const condHeader = (props) => {
  if (props.heading) {
    return <div className="panel-heading">{props.heading}</div>;
  }
  return '';
};

const Panel = (props) => (
  <div className="panel panel-default">
    {condHeader(props)}
    <div className="panel-body">
      {props.children}
    </div>
  </div>
);


condHeader.propTypes = {
  heading: PropTypes.string,
};

Panel.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.children,
};


export default Panel;
